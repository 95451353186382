import { defineStore } from 'pinia'
import { i18n } from '@/helpers/i18n'
import { useAlertStore } from './alert.store'
import OrganizationCategoryService from '@/services/organizationCategory.service'

export const useOrganizationCategoryStore = defineStore('organizationCategory', {
    state: () => ({
        data: []
    }),
    getters: {
        getCategoryByName: (state) => {
            return (name) => {
                let val = state.data.find(item => item.name === name)
                return val ? val : {}
            }
        },
        getData: (state) => state.data,
        // computed properties...
    },
    actions: {
        async fetch() {
            if(!this.getLocal()) {
                await this.getPersistent()
            }
            return this.data
        },
        getLocal() {
            if(this.data.length == 0) {
                this.data = JSON.parse(sessionStorage.getItem('organizationCategories')) || []
            }
            return this.data.length > 0
        },
        async getPersistent() {
            if(this.data.length == 0) {
                await OrganizationCategoryService.getAll()
                .then(response => {
                    this.data = response.data || []
                    sessionStorage.setItem('organizationCategories', JSON.stringify(this.data))
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
            }
            return this.data.length > 0
        },
        async fetchAll() {
            this.data = []
            await OrganizationCategoryService.getAll()
                .then(response => {
                    this.data = response.data || []
                    if(!this.data.length) {
                        let message = i18n.t('message.empty-list')
                        useAlertStore().info(message)
                    }
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async save(category) {
            await OrganizationCategoryService.save(category)
                .then((response) => {
                    this.data.push(response.data)
                    let message = i18n.t('message.category.added')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async update(id, category) {
            await OrganizationCategoryService.update(id, category)
                .then((response) => {
                    this.data = this.data.map(item => {
                        if(item.id === id) {
                          return response.data
                        }
                        return item
                    })
                    let message = i18n.t('message.category.updated')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        },
        async removeById(id) {
            await OrganizationCategoryService.removeById(id)
                .then(() => {
                    this.data = this.data.filter(item => item.id !== id)
                    let message = i18n.t('message.category.removed')
                    useAlertStore().success(message)
                })
                .catch(error => {
                    useAlertStore().error(error)
                })
        }
    }
})