<template>
  <loading v-model:active="loading" :can-cancel="false" :is-full-page="true" />
  <div class="row">
    <div class="col-lg-8">
      <div class="card mb-4">
        <div class="card-header pb-0">
          <div class="d-flex align-items-center">
            <p class="text-uppercase text-sm">{{  $t('section.information') }}</p>
          </div>
        </div>
        <div class="card-body">
          <form>
            <div v-if="organization.id" class="row" style="display: none;">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="Id" class="control-label">{{  $t('field.id') }}</label>
                  <input type="text"
                      id="Id"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="organization.id"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{  $t('field.name') }}</label>
                  <input type="text"
                      label="Name"
                      v-bind:placeholder="$t('placeholder.name')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="organization.name"/>
                </div>
              </div>
              <!--div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{  $t('field.active') }}</label>
                  <div class="form-check form-switch">
                    <input class="form-check-input" type="checkbox" data-toggle="switch"
                        :checked="[ isOn ? true : false ]"
                        v-model="organization.active" />
                    <label class="form-check-label">{{  $t('placeholder.active') }}</label>
                  </div>
                </div>
              </div-->
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <select-store
                      v-bind:placeholder="$t('placeholder.organizationCategory')"
                      v-bind:label="$t('field.category')"
                      keyvalue="definition"
                      optionvalue="name"
                      v-model="organization.category" />
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{  $t('field.specializations') }}</label>
                  <tags-input element-id="specializationsTags"
                      ref="specializationsTagsInput"
                      :placeholder="$t('placeholder.specializationsTags')"
                      :existing-tags="specializations"
                      id-field="code"
                      text-field="name"
                      :value="specializationsSelectedNodes"
                      :typeahead-activation-threshold="3"
                      :typeahead="true"
                      :typeahead-hide-discard="true"
                      :hide-input-on-limit="true"
                      :add-tags-on-comma="true"
                      wrapper-class="form-control"
                      @tag-added="addSpecialization"
                      @tag-removed="removeSpecialization"
                      />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{  $t('field.age') }}</label>
                  <input type="text"
                      label="Age"
                      v-bind:placeholder="$t('placeholder.age')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="organization.age"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label">{{  $t('field.horaire') }}</label>
                  <input type="text"
                      label="Horaire"
                      v-bind:placeholder="$t('placeholder.horaire')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="organization.horaire"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="control-label">{{  $t('field.extraDetails') }}</label>
                  <textarea type="text" rows="6"
                      label="extraDetails"
                      v-bind:placeholder="$t('placeholder.organizationExtraDetails')"
                      class="form-control"
                      aria-describedby="addon-right addon-left"
                      v-model="organization.extraDetails"/>
                </div>
              </div>
            </div>

            <div v-if="isAdmin" class="row">
              <div class="col-md-12 d-flex justify-content-center">
                <button v-if="organization.id" type="submit" class="btn btn-info btn-fill float-right m-2" @click.prevent="update">
                  {{  $t('button.update') }}
                </button>
                <button v-else type="submit" class="btn btn-primary btn-fill float-right m-2" @click.prevent="save">
                  {{  $t('button.add') }}
                </button>
                <button v-if="organization.id" type="submit" class="btn btn-outline-danger btn-fill float-right m-2" @click.prevent="remove">
                  {{  $t('button.remove') }}
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <CardAddress
          :id="organization.id"
          @update:address="onUpdateAddress"
          v-model="organization.address"
      />
      <CardContact
          :id="organization.id"
          @add:contact="onAddContact"
          @update:contact="onUpdateContact"
          @remove:contact="onRemoveContact"
          v-model="organization.contacts"
      />
      <CardTelecom
          :id="organization.id"
          @add:telecom="onAddTelecom"
          @update:telecom="onUpdateTelecom"
          @remove:telecom="onRemoveTelecom"
          v-model="organization.telecom"
      />
    </div>
  </div>
</template>

<script>
  import { provide } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  import { storeToRefs } from 'pinia'
  import { i18n } from '@/helpers/i18n'
  import { useOrganizationStore } from '@/stores/organization.store'
  import { useOrganizationCategoryStore } from '@/stores/organizationCategory.store'
  import { useSpecializationStore } from '@/stores/specialization.store'
  import { useAuthStore } from '@/stores/auth.store'

  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/css/index.css'
  import SelectStore from '@/components/Select/SelectStore.vue'
  import TagsInput from '@/components/Tags/TagsInput.vue'
  import CardAddress from '@/components/Cards/CardAddress.vue'
  import CardContact from '@/components/Cards/CardContact.vue'
  import CardTelecom from '@/components/Cards/CardTelecom.vue'

  export default {
    components: {
      Loading,
      SelectStore,
      TagsInput,
      CardAddress,
      CardContact,
      CardTelecom
    },
    data () {
      return {
        isOn: false,
        specializationsSelectedNodes: [],
      }
    },
    setup() {
      const route = useRoute()
      const router = useRouter()

      const categoryStore = useOrganizationCategoryStore()
      const { getCategoryByName } = categoryStore
      provide('select-store', categoryStore)

      const specializationStore = useSpecializationStore()
      const { specializations } = storeToRefs(specializationStore)
      specializationStore.fetchList()

      const store = useOrganizationStore()
      const { organization, loading } = storeToRefs(store)
      const { fetchById, reset } = store

      const authStore = useAuthStore()
      const { isAdmin } = storeToRefs(authStore)

      const id = route.params.id
      id !== '0' ? fetchById(id) : reset()

      const forward = (refresh = false) => {
        const category = getCategoryByName(organization.value.category)
        let url = null
        switch(category.group) {
          case 'HOSPITAL':
            url = '/hospitals'
            break
          case 'HEALTHCENTER':
            url = '/healthcenters'
            break
          case 'PSYCHIATRY':
            url = '/psychiatry'
            break
          case 'OTHER':
          case 'ASSOCIATION':
            url = '/othercenters'
            break
          default:
            url = '/hospitals'
        }
        url += refresh ? '?refresh=true' : '/'+organization.value.id
        router.push(url)
      }
      const save = () => {
        store.save(organization.value)
      }
      const update = () => {
        store.update(id, organization.value)
      }
      const remove = () => {
        let resp = confirm(i18n.t('modal.remove.organization'))
        if(resp) {
          store.remove(id)
          forward(true)
        }
      }
      const updateAddress = (id, address) => {
        store.updateAddress(id, address)
      }
      const addContact = (id, contact) => {
        store.addContact(id, contact)
      }
      const updateContact = (id, contact) => {
        store.updateContact(id, contact)
      }
      const removeContact = (id, contact) => {
        store.removeContact(id, contact)
      }
      const addTelecom = (id, telecom) => {
        store.updateTelecom(id, telecom)
      }
      const updateTelecom = (id, telecom) => {
        store.updateTelecom(id, telecom)
      }
      const removeTelecom = (id, telecom) => {
        store.removeTelecom(id, telecom)
      }

      return {
        id,
        organization,
        specializations,
        loading,
        save, update, remove,
        updateAddress,
        addContact, updateContact, removeContact,
        addTelecom, updateTelecom, removeTelecom,
        isAdmin
      }
    },
    computed: {
      specializationsNames() {
        return this.specializationsSelectedNodes.map((item) => {
          return item.name
        })
      }
    },
    watch: {
      organization() {
        if(this.organization.specializations) {
          this.specializationsSelectedNodes = []
          let mytags = this.organization.specializations.split(', ')
          mytags.forEach((item, index) => {
            let node = { code: index.toString(), name: item }
            this.specializationsSelectedNodes.push(node)
          })
        }
        if(!this.organization.contacts) {
          this.organization.contacts = []
        }
        if(!this.organization.telecom) {
          this.organization.telecom = []
        }
      }
    },
    methods: {
      onSwitch() {
        this.isOn = !this.isOn
        this.organization.active = this.isOn
      },
      addSpecialization(tag) {
        let isMatch = this.specializationsSelectedNodes.find(item => {
          return item.name === tag.name
        })
        if(!isMatch) {
          this.specializationsSelectedNodes.push(tag);
          this.organization.specializations = this.specializationsNames.join(', ')
        }
      },
      removeSpecialization(tag) {
        let index = this.specializationsSelectedNodes.indexOf(tag.name)
        this.specializationsSelectedNodes.splice(index, 1);
        this.organization.specializations = this.specializationsNames.join(', ')
      },

      // handling address

      onUpdateAddress(address) {
        console.debug(address)
        this.updateAddress(this.id, address)
      },

      // handling contact single entry

      onAddContact(contact) {
        console.debug(contact)
        this.addContact(this.id, contact)
      },
      onUpdateContact(contact) {
        console.debug(contact)
        this.updateContact(this.id, contact)
      },
      onRemoveContact(contact) {
        console.debug(contact)
        this.removeContact(this.id, contact)
      },

      // handling telecom list

      onAddTelecom(telecom) {
        console.debug(telecom)
        this.organization.telecom.push(telecom)
        this.addTelecom(this.id, this.organization.telecom)
      },
      onUpdateTelecom(telecom, oldValue) {
        console.debug(telecom)
        this.organization.telecom = this.organization.telecom.map(item => {
          if(item === oldValue) {
            return telecom
          }
          return item
        })
        this.updateTelecom(this.id, this.organization.telecom)
      },
      onRemoveTelecom(telecom) {
        console.debug(telecom)
        this.organization.telecom = this.organization.telecom.filter(item => item !== telecom)
        this.updateTelecom(this.id, this.organization.telecom)
      }
    }
  }
</script>
